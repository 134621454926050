import { Component, HostListener, OnInit } from '@angular/core';
import products from './../../../assets/data/products.json';
import { of } from 'rxjs';
import { distinct } from 'rxjs/operators';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  productList: any[] = products.Urunler.Urun;
  productListSame: any[] = products.Urunler.Urun;
  viewedProducts: any[] = [];
  index: number = 0;
  selectedCategorie: any;
  allCategories: any[] = ["Tümünü Getir"];

  constructor() { }

  ngOnInit() {
    const unique = this.productList.map(item => item.Kategori);
    
    var filterArray = unique.filter(this.onlyUnique);

    filterArray.forEach(element => {
      this.allCategories.push(element);      
    });

    this.viewedProducts = this.productListSame.slice(0, 18);
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  selectCategorie(selectedCategorie: any){
    if(selectedCategorie != undefined && selectedCategorie != null) {
      this.selectedCategorie = selectedCategorie;

      if(selectedCategorie == "Tümünü Getir") {
        this.productListSame = this.productList;        
      }else{
        this.productListSame = this.productList;
        this.productListSame = this.productListSame.filter(x => x.Kategori == this.selectedCategorie);
      }

      var arrayLength = this.productListSame.length;

      if(arrayLength >= 18)
        this.viewedProducts = this.productListSame.slice(0, 18);
      else 
        this.viewedProducts = this.productListSame.slice(0, arrayLength);
    } 
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {

    const pos = window.scrollY + window.innerHeight;
    const max = document.body.scrollHeight;

    if(pos == max )   {
 
      this.index++;
      var minIndex = (18 + (12 * (this.index - 1)));
      var maxIndex = (18 + (this.index * 12));

      if(this.productListSame.length < minIndex)
        minIndex = this.productListSame.length;
      if(this.productListSame.length < maxIndex)
        maxIndex = this.productListSame.length;

    var newItems = this.productListSame.slice(minIndex, maxIndex);

    newItems.forEach(element => {
      this.viewedProducts.push(element);    
    });

  }
}
}
