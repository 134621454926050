<nav class="navbar navbar-expand-lg navbar-dark bg-dark" [ngStyle]="{ 'background': activeRoute == '/home' ? 'none' : 'url(../../../assets/photo1.jpg)'}" style="z-index: 999999;position: relative;">

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" (click)="collapseMenu()">
      <span class="fas fa-bars" style="color: #0F75BC"></span>
    </button>
  
    <div [@expandCollapse]="collapseStatu" class="collapse navbar-collapse " id="navbarSupportedContent">
      <ul id="RouteMenu" class="navbar-nav mr-auto">
        <li class="nav-item btn" [routerLink]="['/home']" (click)="collapse()">
          <a>ANA SAYFA</a>
        </li>
        <li class="nav-item btn" [routerLink]="['/products']" (click)="collapse()">
          <a>ÜRÜNLER</a>
        </li>
        <li class="nav-item btn" [routerLink]="['/solutions']" (click)="collapse()">
          <a>ÇÖZÜMLER</a>
        </li>
        <li class="nav-item btn" [routerLink]="['/about-us']" (click)="collapse()"> 
          <a>HAKKIMIZDA</a>
        </li>
        <li class="nav-item btn" [routerLink]="['/contact']" (click)="collapse()">
            <a>İLETİŞİM</a>
          </li>
      </ul>
  
    </div>
</nav>
  <div *ngIf="activeRoute == '/home'" class="container-image">
    <img src="../../../assets/photo1.jpg" style="width: 100%">
    <div class="centered">
      <h1>KAMERA VE GÜVENLİK SİSTEMLERİ İÇİN ARADIĞINIZ YERDESİNİZ...</h1>
      <br>
      <h4>Kaliteli ve güvenilir hizmet, son teknoloji ürünler</h4>
      <br><br>
      <div class="btn image-contact-btn" style="color: #fff;" [routerLink]="['/contact']">
        İletişime Geç
      </div>
    </div>
  </div>
  <br><br>