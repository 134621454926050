<div style="padding: 25px 15%;">
<div style="border-radius: 10px;padding: 25px;margin: 10px">
<div class="item_header">
  <i class="fas fa-video fa-3x"></i>
</div>
<hr>
<div>
  Güvenlik kameraları ihtiyaçlara göre çeşitlilik göstermektedir. Ip kamera ve AHD kameralar en çok tercih edilen kamera çeşitleri arasında bulunmaktadır. 
  Kullanım yerlerine göre de AHD, CVI, HD-TVI, IP gibi kamera çeşitlerini seçmeden önce alanda keşif yapılması ve doğru seçim yapılması çok önemlidir. Bütçe de önemli bir nokta
  kabul edildiğinden kullanım ihtiyacınıza göre en uygun kamera tipleri sizlere sunulmaktadır. En uygun yerlerin belirlenmesi ve bu işlemin en üst düzeyde verim alacak şekilde
  yapılması büyük önem arz etmektedir. Ayrıca kayıt sisteminin de kayıt boyutuna göre uygun seçilmesi gerekmektedir. Bu şekilde ihtiyaç durumunda önceki tarihlere ait kayıtlara 
  erişimde herhangi bir sorunla karşılaşılmayacaktır.
</div>
</div>
<br>

<div style="border-radius: 10px;padding: 25px;margin: 10px">
  <div class="item_header">
    <i class="fas fa-shield-alt fa-3x"></i>
  </div>
  <hr>
  <div>
    Güvenlik kameraları her ne kadar tehditler için caydırıcı olsa da farklı önlemlerin alınması da gerekebilmektedir. Bu noktada alarm sistemleri ev, ofis, iş yerleri için 
    etkin bir çözüm sunmaktadır. Çeşitli özelliklerine ve ihtiyacınıza göre uygun alarm sisteminin seçilmesi gerekmektedir. Alarm sisteminin istediğiniz vakitlerde aktif hale getirilmesiyle
    gönül rahatlığı içerisinde hayatınıza devam edebilirsiniz
  </div>
  </div>
<br>
<div style="border-radius: 10px;padding: 25px;margin: 10px">
  <div class="item_header">
    <i class="fas fa-bolt fa-3x"></i>
  </div>
  <hr>
  <div>
    Varolan sistemlerinizin bakımı ve ihtiyaç durumunda yenilenmesi de önemli bir kuraldır. Bu bakımların da zamanında ve periyodik olarak yapılması gerekmektedir. Zamanında yapılan 
    bakımlarla daha sonradan oluşabilecek sorunların büyük ölçüde önüne geçebilirsiniz. İhtiyacınız doğrultusunda en uygun çözümü sunabiliriz.
  </div>
  </div>
</div>