<div class="row" style="margin: 10px;">
  <div style="width: 100%;margin-bottom: 10px;"> 
    <select (change)="selectCategorie($event.target.value)">
      <option *ngFor="let c of allCategories">{{c}}</option>
    </select>
  </div>
  <div *ngFor="let item of viewedProducts" class="col-12 col-lg-2" style="margin-bottom: 10px;">
    <div class="mat-card" style="text-align: center;padding: 10px;height: 100%;">
      <div><img style="width: 100%;height: 250px;" [src]="item.Resimler.Resim" /></div>
      <br />
      <!-- <div>
        <span >{{item.Baslik}}</span>
      </div> -->
      <hr style="width: 80%;margin-left: 10%" />
      <div>
        <h6><span>Kategori Adı : </span><span>{{item.Kategori}}</span></h6>
        <h6><span>Marka : </span><span>{{item.Marka}}</span></h6>
        <h6><span>Model : </span><span>{{item.Kod}}</span></h6>
      </div>
    </div>
  </div>
</div>