<div class="container">
  <div style="width: 100%;text-align: center"><h2>Başlıca Çözümler</h2></div>
  <hr style="width: 90%;color: lightgray;height: 1px;" />
  <div class="row">
    <div class="col-12 col-lg-4">
      <div class="mat-card solutions" style="text-align: center;padding: 10px">
        <div><i class="fas fa-video fa-3x"></i></div>
        <br />
        <hr style="width: 80%;margin-left: 10%" />
        <div>
          <p
            style="display: -webkit-box;-webkit-line-clamp: 10;-webkit-box-orient: vertical;overflow:hidden;text-overflow:ellipsis"
          >
          Güvenlik kameraları ihtiyaçlara göre çeşitlilik göstermektedir. Ip kamera ve AHD kameralar en çok tercih edilen kamera çeşitleri arasında bulunmaktadır. 
          Kullanım yerlerine göre de AHD, CVI, HD-TVI, IP gibi kamera çeşitlerini seçmeden önce alanda keşif yapılması ve doğru seçim yapılması çok önemlidir. Bütçe de önemli bir nokta
          kabul edildiğinden kullanım ihtiyacınıza göre en uygun kamera tipleri sizlere sunulmaktadır. En uygun yerlerin belirlenmesi ve bu işlemin en üst düzeyde verim alacak şekilde
          yapılması büyük önem arz etmektedir. Ayrıca kayıt sisteminin de kayıt boyutuna göre uygun seçilmesi gerekmektedir. Bu şekilde ihtiyaç durumunda önceki tarihlere ait kayıtlara 
          erişimde herhangi bir sorunla karşılaşılmayacaktır.
          </p>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-4">
      <div class="mat-card solutions" style="text-align: center;padding: 10px">
        <div><i class="fas fa-shield-alt fa-3x"></i></div>
        <br />
        <hr style="width: 80%;margin-left: 10%" />
        <div>
          <p
            style="display: -webkit-box;-webkit-line-clamp: 10;-webkit-box-orient: vertical;overflow:hidden;text-overflow:ellipsis"
          >
          Güvenlik kameraları her ne kadar tehditler için caydırıcı olsa da farklı önlemlerin alınması da gerekebilmektedir. Bu noktada alarm sistemleri ev, ofis, iş yerleri için 
          etkin bir çözüm sunmaktadır. Çeşitli özelliklerine ve ihtiyacınıza göre uygun alarm sisteminin seçilmesi gerekmektedir. Alarm sisteminin istediğiniz vakitlerde aktif hale getirilmesiyle
          gönül rahatlığı içerisinde hayatınıza devam edebilirsiniz
          </p>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-4">
      <div class="mat-card solutions" style="text-align: center;padding: 10px">
        <div><i class="fas fa-bolt fa-3x"></i></div>
        <br />
        <hr style="width: 80%;margin-left: 10%" />
        <div>
          <p
            style="display: -webkit-box;-webkit-line-clamp: 10;-webkit-box-orient: vertical;overflow:hidden;text-overflow:ellipsis"
          >
          Yeni kurulan / varolan sistemlerinizin bakımı ve ihtiyaç durumunda yenilenmesi de önemli bir kuraldır. Bu bakımların da zamanında ve periyodik olarak yapılması gerekmektedir. Zamanında yapılan 
          bakımlarla daha sonradan oluşabilecek sorunların büyük ölçüde önüne geçebilirsiniz. İhtiyacınız doğrultusunda en uygun çözümü sunabiliriz. 
          </p>
        </div>
      </div>
    </div>
  </div>
  <br /><br /><br />

  <div style="width: 100%;text-align: center">
    <h2>En Çok Tercih Edilen Ürünler</h2>
  </div>
  <hr style="width: 90%;color: lightgray;height: 1px;" />
  <div class="row">
    <div *ngFor="let p of selectedProducts" class="col-12 col-lg-4">
      <div class="mat-card" style="text-align: center;padding: 10px;height: 100%;">
        <div><img [src]="p.Resimler.Resim" /></div>
        <br />
        <hr style="width: 80%;margin-left: 10%" />
        <div>
          <h6><span>Kategori Adı : </span><span>{{p.Kategori}}</span></h6>
          <h6><span>Marka : </span><span>{{p.Marka}}</span></h6>
          <h6><span>Model : </span><span>{{p.Kod}}</span></h6>
        </div>
      </div>
    </div>
  </div>

  <br /><br /><br />

  <div style="width: 100%;text-align: center"><h2>Müşteri Yorumları</h2></div>
  <hr style="width: 90%;color: lightgray;height: 1px;" />
  <div class="row">
    <div class="col-lg-12">
      <div class="mat-card" style="text-align: center;padding: 10px">
        <div class="row">
          <div class="col-3"><img src="../../../assets/logos/ref3.png" /></div>
          <div class="col-9">
            <p
              style="display: -webkit-box;-webkit-line-clamp: 10;-webkit-box-orient: vertical;overflow:hidden;text-overflow:ellipsis"
            >
              İşinin ehli bir şekilde gerçekten özveriyle çalışarak bizlere hizmet verdiler.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12">
      <div class="mat-card" style="text-align: center;padding: 10px">
        <div class="row">
          <div class="col-3"><img src="../../../assets/logos/ref2.png" /></div>
          <div class="col-9">
            <p
              style="display: -webkit-box;-webkit-line-clamp: 10;-webkit-box-orient: vertical;overflow:hidden;text-overflow:ellipsis"
            >
            Güvenlik sisteminin, kameralarının takılması için kendileriyle çalıştık. Belirlenen süre içerisinde başarıyla işi teslim ettiler ve herhangi bir problem yaşanmadı. Hizmetleri 
            için teşekkürlerimizi sunuyoruz.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12">
      <div class="mat-card" style="text-align: center;padding: 10px">
        <div class="row">
          <div class="col-3"><img src="../../../assets/logos/ref1.png" /></div>
          <div class="col-9">
            <p
              style="display: -webkit-box;-webkit-line-clamp: 10;-webkit-box-orient: vertical;overflow:hidden;text-overflow:ellipsis"
            >
              Şubelerimizin elektrik altyapısının değişmesi, güvenlik kameralarının yenilenmesi konusunda destek aldık. Sonuç olarak gayet güzel bir şey ortaya çıkarıldı. 
              İhtiyacımız olursa yine danışacağımız bir yer.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <br /><br /><br />

  <div style="width: 100%;text-align: center"><h2>Referanslar</h2></div>
  <hr style="width: 90%;color: lightgray;height: 1px;" />
  <div class="row" style="text-align: center">
    <div class="col-3 col-lg-1">
      <img
        src="../../../assets/logos/ref1.png"
        style="max-width: 100px;max-height: 100px"
      />
    </div>
    <div class="col-3 col-lg-1">
      <img
        src="../../../assets/logos/ref2.png"
        style="max-width: 100px;max-height: 100px"
      />
    </div>
    <div class="col-3 col-lg-1">
      <img
        src="../../../assets/logos/ref3.png"
        style="max-width: 100px;max-height: 100px"
      />
    </div>
    <div class="col-3 col-lg-1">
      <img
        src="../../../assets/logos/ref1.png"
        style="max-width: 100px;max-height: 100px"
      />
    </div>
    <div class="col-3 col-lg-1">
      <img
        src="../../../assets/logos/ref2.png"
        style="max-width: 100px;max-height: 100px"
      />
    </div>
    <div class="col-3 col-lg-1">
      <img
        src="../../../assets/logos/ref3.png"
        style="max-width: 100px;max-height: 100px"
      />
    </div>
    <div class="col-3 col-lg-1">
      <img
        src="../../../assets/logos/ref1.png"
        style="max-width: 100px;max-height: 100px"
      />
    </div>
    <div class="col-3 col-lg-1">
      <img
        src="../../../assets/logos/ref2.png"
        style="max-width: 100px;max-height: 100px"
      />
    </div>
    <div class="col-3 col-lg-1">
      <img
        src="../../../assets/logos/ref3.png"
        style="max-width: 100px;max-height: 100px"
      />
    </div>
    <div class="col-3 col-lg-1">
      <img
        src="../../../assets/logos/ref1.png"
        style="max-width: 100px;max-height: 100px"
      />
    </div>
    <div class="col-3 col-lg-1">
      <img
        src="../../../assets/logos/ref2.png"
        style="max-width: 100px;max-height: 100px"
      />
    </div>
    <div class="col-3 col-lg-1">
      <img
        src="../../../assets/logos/ref3.png"
        style="max-width: 100px;max-height: 100px"
      />
    </div>
    <div class="col-3 col-lg-1">
      <img
        src="../../../assets/logos/ref1.png"
        style="max-width: 100px;max-height: 100px"
      />
    </div>
    <div class="col-3 col-lg-1">
      <img
        src="../../../assets/logos/ref2.png"
        style="max-width: 100px;max-height: 100px"
      />
    </div>
    <div class="col-3 col-lg-1">
      <img
        src="../../../assets/logos/ref3.png"
        style="max-width: 100px;max-height: 100px"
      />
    </div>
    <div class="col-3 col-lg-1">
      <img
        src="../../../assets/logos/ref1.png"
        style="max-width: 100px;max-height: 100px"
      />
    </div>
    <div class="col-3 col-lg-1">
      <img
        src="../../../assets/logos/ref2.png"
        style="max-width: 100px;max-height: 100px"
      />
    </div>
    <div class="col-3 col-lg-1">
      <img
        src="../../../assets/logos/ref3.png"
        style="max-width: 100px;max-height: 100px"
      />
    </div>
    <div class="col-3 col-lg-1">
      <img
        src="../../../assets/logos/ref1.png"
        style="max-width: 100px;max-height: 100px"
      />
    </div>
    <div class="col-3 col-lg-1">
      <img
        src="../../../assets/logos/ref2.png"
        style="max-width: 100px;max-height: 100px"
      />
    </div>
    <div class="col-3 col-lg-1">
      <img
        src="../../../assets/logos/ref3.png"
        style="max-width: 100px;max-height: 100px"
      />
    </div>
  </div>
</div>
