<nav class="navbar navbar-dark bg-dark mt-5">
  <div class="navbar-expand m-auto navbar-text" style="width: 100% !important;">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 contact">
        <h5 style="text-align: center">İletişim</h5>
        <hr />
        <h6><i class="fas fa-phone"></i> 0535 819 71 27</h6>
        <h6><i class="fas fa-envelope"></i> orhan.kocak@hotmail.com</h6>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
        <h5 style="text-align: center">Sosyal Medyada Biz</h5>
        <hr />
        <div class="row" style="text-align: center">
          <div class="col"><i class="fab fa-facebook fa-3x"></i></div>
          <div class="col"><i class="fab fa-instagram fa-3x" style="cursor: pointer;" (click)="openSocialMedia('https://www.instagram.com/vestaguvenliksistemleri/')"></i></div>
          <div class="col"><i class="fab fa-twitter-square fa-3x"></i></div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 contact">
        <h5 style="text-align: center">Adres</h5>
        <hr />
        <h6><i class="fas fa-building"></i> 
        <span> Mustafa Kemal Paşa Mh. Köroğlu Sok. No:148 34320 Beylikdüzü Osb/Avcılar/İstanbul</span>
        </h6>
      </div>
    </div>
  </div>
</nav>
