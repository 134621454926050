import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit, ElementRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('expandCollapse', [
        state('show', style({
            'height': '*'
        })),
        state('collapse', style({
            'height': 'unset'
        })),
        transition('show => collapse', animate(300))
    ])
]
})
export class HeaderComponent implements OnInit {

  activeRoute: any;
  collapseStatu: any = 'collapse';
  constructor(private el: ElementRef,
    private router: Router) {
      router.events.subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          console.log(this.router.url);
          this.activeRoute = this.router.url;
        }
        //console.log(val instanceof NavigationEnd);
      })
     }

  ngOnInit() {
    //console.log(this.activeRoute);
  }

  collapseMenu() {
    this.collapseStatu = this.collapseStatu == 'collapse' ? 'show' : 'collapse';
  }

  collapse() {
    this.collapseStatu = 'collapse';
    setTimeout(() => {
      let myTag = this.el.nativeElement.querySelector("#navbarSupportedContent");

      if(myTag.classList.contains("show")) {
        myTag.classList.remove('show');
      }
    }, 200);

  }
}
