import { AboutUsComponent } from './../pages/other/about-us/about-us.component';
import { SolutionsComponent } from './../pages/other/solutions/solutions.component';
import { ContactComponent } from './../pages/other/contact/contact.component';
import { ProductsComponent } from './../pages/other/products/products.component';
import { FooterComponent } from './../pages/shared/footer/footer.component';
import { HeaderComponent } from './../pages/shared/header/header.component';
import { HomeComponent } from './../pages/other/home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { LayoutComponent } from 'src/pages/shared/layout/layout.component';



const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },

  { path: '',
    component: LayoutComponent ,
    children: [
      { path: 'home', component: HomeComponent, pathMatch: 'full'},
      { path: 'products', component: ProductsComponent, pathMatch: 'full'},
      { path: 'contact', component: ContactComponent, pathMatch: 'full'},
      { path: 'solutions', component: SolutionsComponent, pathMatch: 'full'},
      { path: 'about-us', component: AboutUsComponent, pathMatch: 'full'}
    ]
  }
];

const config: ExtraOptions = {
  useHash: true
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const routingComponents = [LayoutComponent, HomeComponent, HeaderComponent, FooterComponent, ProductsComponent, ContactComponent, SolutionsComponent, AboutUsComponent];
