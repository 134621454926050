<div class="row" style="margin: 0;
width: 100%;
margin-top: -50px;
height: 100%;">
<div class="col-12" style="padding: 0;">
  <agm-map [latitude]="lat" [longitude]="lng" style="width: 100%;height: 60vh;">
    <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
  </agm-map>
</div>
<div class="col-12 row;margin:0">
  <div class="col-12 row" style="display: flex;justify-content: center;align-items: center;margin: 0;">
    <div class="col-12" style="text-align: center;font-size: larger;font-weight: 900;color: lightslategray;">
      ADRES BİLGİLERİ
      <hr>
    </div>
    <div class="col-12" style="text-align: center;color: gray;">
      <i class="fas fa-building"></i> Mustafa Kemal Paşa Mh. Köroğlu Sok. No:148 34320 Beylikdüzü Osb/Avcılar/İstanbul
    </div>
    <div class="col-12" style="text-align: center;color: gray;">
      <i class="fas fa-phone"></i> 0535 819 71 27
    </div>
    <div class="col-12" style="text-align: center;color: gray;">
      <i class="fas fa-envelope"></i> orhan.kocak@hotmail.com
    </div>
  </div>
    <!-- <form>
        <input placeholder="İsim" type="text"  value="" required>
        <input placeholder="Mail Adresi" type="email" onblur="this.setAttribute('value', this.value);" value="" required>
        <span class="validation-text">Lütfen geçerli mail adresi girin.</span>
        <div class="flex">
          <textarea placeholder="Mesaj" rows="5" required></textarea>
        </div>
        <button>Gönder</button>
      </form> -->
</div>
</div>
