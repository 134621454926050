import { Component, OnInit } from '@angular/core';
import products from './../../../assets/data/products.json';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  selectedProducts: any[];
  constructor() { }

  ngOnInit() {
    this.selectedProducts = products.Urunler.Urun.filter(x => x.Stoklar.Stok.StokMiktari >= 100);
    console.log(this.selectedProducts);
    if(this.selectedProducts.length >= 3){
      this.selectedProducts = this.selectedProducts.slice(0,3);
    }
  }

}
