<div class="container">

  <div class="mat-card" style="padding: 15px">

    <br>
    <br>
      <h4>Neler Yapıyoruz?</h4>
      <hr>
     <p style="color: gray;">İstanbul ve çevresi için yılların deneyimi ve son teknoloji ürünlerle bütçeniz doğrultusunda en kaliteli hizmeti vermekteyiz. 
       Ev, ofis, işyerleri, fabrikalar da dahil olmak üzere bir çok alanda güvenlik sistemlerinin kurulumu ve yenilenmesi çalışmalarını yapmaktayız. 
       Güvenlik kameraları, ip kameralar, dome kameralar, kayıt sistemleri ve çok daha fazla elektrik-elektronik ürünle ihtiyaçlarınızın giderilmesi bizim için olmazsa olmaz bir kuraldır.
      </p> 
      <br>
      <p style="color: gray;">
        Her türlü güvenlik ve kamera sistemleri için her zaman sizlere hizmet verebilmek için buradayız. 
      </p>
      <br>
      <br>
     
      <h4>Güvenlik</h4>
      <hr>
     <p style="color: gray;">Güvenlik, hem kişisel olarak hem de kurumsal olarak her kesimin ihtiyacıdır. 
       En değer verdiğiniz eşyalarınızın, emekle kurduğunuz işinizin gün içerisinde bir zarar görmemesi ya da zarar geldiyse kim
       ve ne tarafından bu durumun oluştuğunu öğrenebilmek, zararın telafisi açısından çok önemlidir. Günümüz teknolojisinin gelişmesiyle birlikte
       güvenlik sistemleri de aynı doğrultuda gelişmiştir. 
      </p> 
      <br>
      <p style="color: gray;">
        Gelişen teknoloji ile sabah akşam farketmeksizin birbirinden farklı özellikli kameralarla
        belirlediğiniz alanların canlı olarak takibini yapabilirsiniz. Kayıt sistemleri ile birlikte belirlediğiniz periyotlarda kameraların kayıt atmasını sağlayabilirsiniz.
        İnternet alt yapısıyla birlikte kameralarınıza, internet bağlantınızın olduğu cihazınızdan her zaman ulaşabilirsiniz. Gelişen bu teknolojiyi kullanarak
        sizin için önem arz eden her şeyin güvenliğini sağlayabilmek ve bunu takip edebilmek artık sizin elinizde.
      </p>
      <br><br>
  </div>


</div>